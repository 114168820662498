import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom"; // Import Link from React Router
import logo from "../assets/images/logo.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
      <>
        <div className="section footer">
          <div className="row">
            <div
                className="logo"
                onClick={() => {
                  window.scrollTo({top: 0, behavior: "smooth"}); // Scroll to top of the page
                }}
            >
              {/* Use Link component from react-router-dom */}
              <Link to="/" className="logo nav-link">
                <img className="logo-footer" src={logo} alt="EsyMedia Logo"/>
              </Link>
            </div>
            <div className="column">
              <p>Navigation</p>
              {/* Use ScrollLink for smooth scrolling */}
              <ScrollLink
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
              >
                <button>About</button>
              </ScrollLink>
              <ScrollLink
                  activeClass="active"
                  to="help"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
              >
                <button>Help</button>
              </ScrollLink>
              {/* Replace the buttons with ScrollLink */}
              <ScrollLink
                  activeClass="active"
                  to="map-section"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
              >
                <button>In Map</button>
              </ScrollLink>
            </div>
            <div className="column">
              <p>Socials</p>
              {/* Add social media links here */}
              <a href="https://www.instagram.com/muranobeachclub/" target="_blank">
                <button>Instagram</button>
              </a>
              <a href="https://www.facebook.com/Muranobeachclub" target="_blank">
                <button>Facebook</button>
              </a>
            </div>
          </div>
          <hr  style={{width: '100%'}}/>
          <div className="copyright d-flex">
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/impressum" onClick={scrollToTop}>
                <button className="copyright-btn">Impressum</button>
              </Link>
              <Link to="/datenschutz" onClick={scrollToTop}>
                <button className="copyright-btn">Datenschutz</button>
              </Link>
            </div>
            <span>Copyright © 2024-2025</span>
          </div>
        </div>
      </>
  );
};

export default Footer;
