import React, { useState } from 'react';
import video from '../assets/video/SUP.mp4';
import supLayout from '../assets/images/Layout_SUP Verleih_A3.jpg';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Footer from '../components/Footer';
import Divider from "../components/Divider";

const Wassersport = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setShowModal(true);
  };

  const closeModal = () => {
    setActiveCategory(null);
    setShowModal(false);
  };

  return (
    <>
      <Navbar />
      <MobileNav />
        <div className="header">
            <video className="header-video" autoPlay loop muted playsInline>
                <source src={video} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <div className="gradient"/>
            <div className="inner">
                <div className="inner-event">
                    <div className="text wassersport-title">
                        <h1
                            className="fade-in"
                            data-aos="fadeInAnimation"
                            data-aos-duration="1000"
                            data-aos-anchor=".text"
                            data-aos-delay="0"
                        >
                            WASSERSPORT
                        </h1>
                    </div>
                    <h2 data-aos="fade-up" data-aos-duration="1000">
                        Genießen Sie die Schönheit der Natur mit
                        unserem <b>Supvermietung</b> und <b>Tretbootverleih</b>. <br/>
                        Erkunden Sie das ruhige Wasser und erleben Sie unvergessliche Momente.
                    </h2>
                    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                        <button
                            className={activeCategory === 'sup' ? 'active' : 'deactivate'}
                            onClick={() => handleCategoryChange('sup')}
                        >
                            Preis
                        </button>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal" onClick={closeModal}>
                    {activeCategory === 'sup' && (
                        <img src={supLayout} alt="Appetizers" className="category-img"/>
                    )}
                </div>
            )}
        </div>
        <Footer/>
    </>
  );
};

export default Wassersport;
