import React, { useState } from 'react';
import video from '../assets/video/Drinks.mp4';
import bierLayout from '../assets/images/Layout_Getränke_Bier.jpg';
import longdrinkLayout from '../assets/images/Layout_Getränke_Longdrinks.jpg';
import softdrinkLayout from '../assets/images/Layout_Getränke_Softdrinks.jpg';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Footer from '../components/Footer';

const Drinks = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setShowModal(true);
  };

  const closeModal = () => {
    setActiveCategory(null);
    setShowModal(false);
  };

  return (
    <>
      <Navbar />
      <MobileNav />
      <div className="header">
        <video className="header-video" autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="gradient" />
        <div className="inner">
          <div className="inner-event">
            <div className="text wassersport-title">
              <h1
                  className="fade-in"
                  data-aos="fadeInAnimation"
                  data-aos-duration="1000"
                  data-aos-anchor=".text"
                  data-aos-delay="0"
                  style={{fontSize: '1.5rem'}}
              >
                UNSERE  GETRÄNKE
              </h1>
            </div>

          <h2 data-aos="fade-up" data-aos-duration="1000" className='drink-text'>
            Verwöhnen Sie Ihren Gaumen im <b>Murano Beachclub </b>mit einer exquisiten Auswahl
            erfrischender <b>Getränke</b>.<br/>
            Entdecken Sie erlesene <b>Biere</b>, edle <b>Weine</b> und spritzige <b>Cocktails</b> in entspannter Atmosphäre.{' '}
            Tauchen Sie ein und erleben Sie unvergessliche Genussmomente am Wasser
          </h2>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
          <div className="categories">
            <button
              className={activeCategory === 'bier' ? 'active' : 'deactivate'}
              onClick={() => handleCategoryChange('bier')}
            >
              BIER, WEIN & SEKT
              <br/>Preis-Liste
            </button>
            <button
              className={activeCategory === 'longdrinks' ? 'active' : 'deactivate'}
              onClick={() => handleCategoryChange('longdrinks')}
            >
              LONGDRINKS & COCKTAILS
              <br/>Preis-Liste
            </button>
            <button
              className={activeCategory === 'softdrinks' ? 'active' : 'deactivate'}
              onClick={() => handleCategoryChange('softdrinks')}
            >
              SOFTDRINKS & KAFFEE
              <br/>Preis-Liste
            </button>
            </div>
          </div>
          </div>
        </div>
        {showModal && (
          <div className="modal" onClick={closeModal}>
            {activeCategory === 'bier' && (
              <img src={bierLayout} alt="Bier Layout" className="category-img" />
            )}
            {activeCategory === 'longdrinks' && (
              <img src={longdrinkLayout} alt="Longdrinks Layout" className="category-img" />
            )}
            {activeCategory === 'softdrinks' && (
              <img src={softdrinkLayout} alt="Softdrinks Layout" className="category-img" />
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Drinks;
