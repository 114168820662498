import React from "react";
import Whatssap from "../assets/icons/whatssap.svg";
import Email from "../assets/icons/email.svg";
import { BSPhoneFill, BSEnvelopeFill } from '@icongo/bs/lib/BSPhoneFill';
const Help = () => {
  return (
    <>
    <div className="section help">
        <div className="inner" data-aos="fade-up" data-aos-duration="1000">
            <h3>RESERVIERUNG</h3>
            <h4>
                Was kannst <b>du tun </b> um eine Reservierung vorzunehmen?
            </h4>
            <h5 className="reservierung-text">
                Sie können Ihren Platz oder Veranstaltungen telefonisch oder per E-Mail unter reservieren.
            </h5>
            <div className="contact-info">
                <div className="d-flex contact-item">
                    <img src={Whatssap} className="icon"/>
                    <span className="contact-text">+49 151 14901502</span>
                </div>
                <div className="d-flex contact-item">
                    <span className="contact-text">info@murano-beachclub.de</span>
                </div>
            </div>

        </div>
    </div>
</>
)
    ;
};

export default Help;