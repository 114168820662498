import React from "react";

const About = () => {
  return (
    <>
      <div className="section about">
        <div className="inner" data-aos="fade-up" data-aos-duration="1000">
          <h3>WER WIR SIND</h3>
          <h4>
          Willkommen im <b>Murano-Beach</b> Club.
          </h4>
          <h5 className="about-text">
          Der Murano Beach Club am idyllischen Murner See ist der perfekte Ort für Entspannung, erfrischende Getränke und Wassersportaktivitäten. Wir bieten eine Vielzahl von Dienstleistungen, wie die Vermietung von Stand-Up Paddle Boards (SUP) und die Organisation von privaten und Firmenevents.
          Genießen Sie entspannte Stunden an unserem wunderschönen Strand und lassen Sie sich von unserem Service verwöhnen.
          </h5>
        </div>
      </div>
    </>
  );
};

  export default About;