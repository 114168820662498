import React from 'react';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Footer from '../components/Footer';

const Impressum = () => {
    return (
        <>
            <Navbar />
            <MobileNav />
            <div className="impressum-header">
                <div className="impressum-gradient" />
                <div className="impressum-inner">
                    <div className="impressum-text wassersport-title">
                        <h1 className="fade-in impressum-title">
                            IMPRESSUM & KONTAKT
                        </h1>
                        <p className="pt-3 pb-3">
                            Murano Beachclub <br />
                            Marc Schäfer <br />
                            Stadtgraben 3 <br />
                            92536 Pfreimd <br />
                            Telefon: 0049 (0) +151 59177062 <br />
                            E-Mail: info@murano-beachclub.de <br />
                        </p>
                        <p className="pt-3 pb-3">
                            Bankverbindung: <br />
                            Sparkasse Landkreis Schwandorf <br />
                            IBAN: DE63 7505 1040 0031 6167 58 <br />
                            SWIFT-BIC: BYLADEM1SAD <br />
                        </p>
                        <p className="pt-3 pb-3">
                            Steuernummer: 248/266/10332 <br />
                            Steuer ID: 72931504968 <br />
                            Verantwortlich für den Inhalt: <br />
                            NAME: Marc Schäfer <br />
                            E-Mail: info@murano-bechclub.de <br />
                        </p>
                        <p className="pt-3 pb-3">
                            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden <a className="impressum-link" href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Impressum;
