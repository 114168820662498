import React from 'react';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Footer from '../components/Footer';

const Datenschutz = () => {
    return (
        <>
            <Navbar />
            <MobileNav />
            <div className="impressum-header">
                <div className="impressum-gradient" />
                <div className="impressum-inner">
                    <div className="impressum-text wassersport-title">
                        <h1 className="fade-in impressum-title">
                            DATENSCHUTZERKLÄRUNG
                        </h1>

                        <p className="pt-10 pb-3">
                            Wir freuen uns über Ihr Interesse an unserer Webseite. Der Schutz Ihrer Privatsphäre ist für
                            uns von größter Bedeutung. Nachfolgend informieren wir Sie ausführlich darüber, wie wir mit
                            Ihren Daten umgehen.
                        </p>
                        <br/>
                        <h3 className="datenschutz-title">"Zugriffsdaten und Hosting"</h3>
                        <p className="pb-3">
                            Sie können unsere Webseite besuchen, ohne persönliche Angaben zu machen. Beim Aufrufen
                            einer
                            Webseite speichert der Webserver automatisch Zugriffsdaten in sogenannten
                            Server-Logfiles.
                            Diese Daten umfassen beispielsweise den Namen der abgerufenen Datei, Ihre IP-Adresse,
                            Datum
                            und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider. Diese
                            Zugriffsdaten werden ausschließlich zur Sicherstellung eines reibungslosen Betriebs der
                            Webseite und zur Verbesserung unseres Angebots verwendet. Die Zugriffsdaten werden
                            spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
                        </p>

                        <br/>
                        <h3 className="datenschutz-title">"Hosting"</h3>
                        <p className="pb-3">
                            Die Dienste zum Hosting und zur Darstellung der Webseite werden von unseren
                            Dienstleistern
                            erbracht, die diese Daten in unserem Auftrag verarbeiten.

                        </p>

                        <h3 className="datenschutz-title">"Datenverarbeitung zur Kontaktaufnahme"</h3>
                        <p className="pb-3">
                            Wir erheben personenbezogene Daten, die Sie uns freiwillig bei einer Kontaktaufnahme
                            (z.B.
                            per Kontaktformular oder E-Mail) mitteilen. Wir verwenden diese Daten nur zur
                            Bearbeitung
                            Ihrer Anfrage. Nach Abschluss der Bearbeitung werden Ihre Daten gelöscht, sofern Sie
                            nicht
                            ausdrücklich in eine weitere Nutzung eingewilligt haben.
                        </p>

                        <br/>
                        <h3 className="datenschutz-title">"Cookies und weitere Technologien"</h3>
                        <p className="pb-3">
                            Um Ihre Nutzung unserer Webseite zu verbessern und bestimmte Funktionen zu ermöglichen,
                            verwenden wir Cookies und ähnliche Technologien. Diese dienen dazu, Ihre Präferenzen zu
                            speichern und die Nutzung der Webseite zu analysieren. Sie können in Ihrem Browser die
                            Einstellungen für Cookies anpassen oder diese ablehnen. Dadurch kann die Funktionalität
                            der
                            Webseite eingeschränkt sein.
                        </p>

                        <br/>
                        <h3 className="datenschutz-title">"Kontaktmöglichkeiten und Ihre Rechte"</h3>
                        <p className="pb-3">
                            Als betroffene Person haben Sie bestimmte Rechte gemäß der Datenschutzgrundverordnung
                            (DSGVO), einschließlich des Rechts auf Auskunft, Berichtigung und Löschung Ihrer Daten.
                            Für weitere Informationen und die Ausübung Ihrer Rechte können Sie sich direkt an uns
                            wenden.
                            Diese Datenschutzerklärung wurde mit dem Trusted Shops Rechtstexter in Zusammenarbeit
                            mit FÖHLISCH Rechtsanwälte erstellt.
                        </p>

                            <br/>
                            <h3 className="datenschutz-title">"Hinweise zur Datenverarbeitung im Zusammenhang mit Google Analytics"</h3>
                        <p className="pb-3">
                            Diese Website nutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited.
                            Google Analytics verwendet Cookies zur Analyse der Benutzung der Webseite.
                            Die durch den
                            Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel
                            an einen Server von Google in den USA übertragen und dort gespeichert.
                            Ihre IP-Adresse wird jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
                            Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google Analytics verhindern, indem Sie das
                            Browser-Add-on zur Deaktivierung von Google Analytics installieren.
                            Für weitere Informationen zur Datennutzung durch Google Analytics und zum Datenschutz
                            bei Google können Sie die Datenschutzerklärung von Google einsehen.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Datenschutz;
