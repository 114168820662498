
import React from "react";
import video from "../assets/video/Murano.mov"
import "/node_modules/aos/dist/aos.css";

const Header = () => {
  return (
    <>
      <div className="header">
        <video className="header-video" autoPlay loop muted playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="gradient" />
        <div className="inner">
          <div className="logo">
          <h1
              className="fade-in"
              data-aos="fadeInAnimation"
              data-aos-duration="1000"
              data-aos-anchor=".text"
              data-aos-delay="0"
            >
              MURANO
            </h1>
            <h1
              className="fade-in"
              data-aos="fadeInAnimation"
              data-aos-duration="1000"
              data-aos-anchor=".text"
              data-aos-delay="100"
            >
              BEACH-CLUB
            </h1>
          </div>
          <h2 data-aos="fade-up" data-aos-duration="1000">
            am idyllischen <b>Murner See</b> ist der perfekte Ort für Entspannung, erfrischende Getränke und Wassersportaktivitäten.!
          </h2>
        </div>
      </div>
    </>
  );
};

export default Header;