import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import MobileNav from '../components/MobileNav';
import Footer from '../components/Footer';
import facebook from '../assets/icons/facebook.png';
import instagramIco from '../assets/icons/instagram.png';
// Import the images
import dfe from '../assets/images/events/dfe.png';
import dfe2 from '../assets/images/beach_palme_sundown.jpeg';
import legallizEvent from '../assets/images/events/Legalliz-event.png';
import rakete from '../assets/images/events/rakete.jpg';
import salsa from '../assets/images/events/salsa.png';
import legalliz from '../assets/images/events/legalizeevent.png';
import salsaLatino from '../assets/images/events/salsa-latino.png';
import sektionTanz from '../assets/images/events/sektion-tanz.jpg';
import tomhintermeier from '../assets/images/events/tonhintermeier.png';
import tomSunset from '../assets/images/events/tom-sunset.jpg';

// Import the videos
import dfeVideo from '../assets/video/events/dfe.MOV';
import dfe2Video from '../assets/video/events/dfe2.MOV';
import legallizVideo from '../assets/video/events/legalize.MP4';
import legalliz2Video from '../assets/video/events/legalize2.MOV';
import raketeVideo from '../assets/video/events/rakete.MOV';
import salsaLatinoVideo from '../assets/video/events/salsa-latino.MOV';
import salsaVideo from '../assets/video/events/salsa.MOV';
import sektionTanzVideo from '../assets/video/events/sektion-tanz.MP4';
import tomSunsetVideo from '../assets/video/events/tom-sunset.MOV';
import tomVideo from '../assets/video/events/tom.MOV';

const eventsArray = [
  {
    id: 'event1',
    imageButtonCombos: [
      { image: dfe, video: dfeVideo },
      { image: dfe2, video: dfe2Video },
    ],
  },
  {
    id: 'event2',
    imageButtonCombos: [
      { image: legallizEvent, video: legalliz2Video },
      { image: rakete, video: raketeVideo },
    ],
  },
  {
    id: 'event3',
    imageButtonCombos: [
      { image: tomSunset, video: tomSunsetVideo },
      { image: salsa, video: salsaVideo },
    ],
  },
  {
    id: 'event4',
    imageButtonCombos: [
      { image: legalliz, video: legallizVideo },
      { image: salsaLatino, video: salsaLatinoVideo },
    ],
  },
  {
    id: 'event5',
    imageButtonCombos: [
      { image: sektionTanz, video: sektionTanzVideo },
      { image: tomhintermeier, video: tomVideo },
    ],
  }
];

const FacebookIcon = () => (
    <a href="https://www.facebook.com/Muranobeachclub" target="_blank" rel="noopener noreferrer">
      <img
          src={facebook}
          alt="Facebook Icon"
          style={{
            width: '60px', // Adjust width as needed
            height: '60px', // Adjust height as needed
            margin: '0 10px', // Add margin between icons
            transition: 'transform 0.2s ease', // Add transition for hover effect
            cursor: 'pointer'
          }}
      />
    </a>
);

const InstagramIcon = () => (
    <a href="https://www.instagram.com/muranobeachclub/" target="_blank" rel="noopener noreferrer">
      <img
          src={instagramIco}
          alt="Instagram Icon"
          style={{
            width: '60px', // Adjust width as needed
            height: '60px', // Adjust height as needed
            margin: '0 10px', // Add margin between icons
            transition: 'transform 0.2s ease', // Add transition for hover effect
            cursor: 'pointer'
          }}
      />
    </a>
);


const EventPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeVideo, setActiveVideo] = useState('');

  const handleCardClick = (video) => {
    setShowModal(true);
    setActiveVideo(video);
  };

  const closeModal = () => {
    setShowModal(false);
    setActiveVideo('');
  };

  return (
      <>
        <Navbar />
        <MobileNav />
        <div className="event-header event-container">
          <div className="gradient"/>
          <div className="inner">
            <div className="inner-event">
              <div className="text wassersport-title">
                <h1
                    className="fade-in"
                    data-aos="fadeInAnimation"
                    data-aos-duration="1000"
                    data-aos-anchor=".text"
                    data-aos-delay="0"
                >
                  EVENTS
                </h1>
              </div>
              <h2 data-aos="fade-up" data-aos-duration="1000" className='drink-text'>
                Besuchen Sie unsere <b>Instagram</b> und <b>Facebook</b>-Seiten, um über bevorstehende Veranstaltungen
                informiert zu
                werden!
              </h2>
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                <div className="categories" style={{display: 'flex', justifyContent: 'center'}}>
                  <FacebookIcon/>
                  <InstagramIcon/>
                </div>
              </div>
            </div>


            <div className="event-cards">
              {eventsArray.map((event) => (
                  <div key={event.id} className="event-card">
                    {event.imageButtonCombos.map(({image, video}, index) => (
                        <div key={index} className="event-card-content">
                        <img className="event-img" src={image} alt={`Event ${event.id}`}/>
                          <button className="event-btn" onClick={() => handleCardClick(video)}>VIDEO ANSEHEN</button>
                        </div>
                    ))}
                  </div>
              ))}
            </div>
          </div>
        </div>
        {showModal && (
            <div className="modal" onClick={closeModal}>
              {activeVideo && (
                  <video src={activeVideo} controls autoPlay className="event-modal"/>
              )}
            </div>
        )}
        <Footer/>
      </>
  );
};

export default EventPage;
