import React from "react";
import devImg from "../assets/images/squiggle-3-1.png";


const Divider = () => {
  return (
    <>
      <div className="section divider">
      <img src={devImg} alt="dev-Img" />
      </div>
    </>
  );
};
  export default Divider;