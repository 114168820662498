import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
  return (
    <CookieConsent
      debug={true}
      style={{
        background: "#f5ac00",
        color: "#000",
        textAlign: "center",
        padding: "15px",
        justifyContent: "center",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 9999,
      }}
      buttonStyle={{ background: "#00507c",
      color: "#fff",
      border: "none",
      marginRight: "30px",
      borderRadius: "4px",
      padding: "8px 16px",
      cursor: "pointer",
      marginLeft: "15px",
      fontWeight: "bold",
      fontSize: "14px",}}
      buttonText= "Verstanden!"
    >
      Diese Website verwendet Cookies. Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu.
    </CookieConsent>
  );
};

export default CookieBanner;
