import Navbar from "../components/Navbar";
import MobileNav from "../components/MobileNav";
import Instagram from "../components/Instagram";
import MapSection from "../components/MapSection";
import Header from "../components/Header"
import Divider from "../components/Divider";
import About from "../components/About";
import Banner from "../components/Banner";
import Help from "../components/Help";
import Footer from "../components/Footer";
import AOS from "aos";

AOS.init({
  once: true
});
const Home = () => {


  return (
    <div className="App">
      <Navbar />
      <MobileNav />
      <Header />
      <Divider />
      <About />
      <Banner />
      <Instagram/>
      <Divider />
      <Help />
      <Divider />
      <MapSection/>
      <Footer />
    </div>
  );
};

export default Home;
