import React from "react";

const Banner = () => {
    return (
      <>
        <div className="section banner">
          <div className="inner">
            <h4 className="fade-in banner-text" data-aos="fadeInAnimation">
              Der durchschnittliche Besucher pro Tag im Sommer beträgt 200 Personen.
            </h4>
          </div>
        </div>
      </>
    );
  };

  export default Banner;