import React from 'react';
import profile from '../assets/images/Logo.final1.jpg';
import insta1 from '../assets/images/insta1.png';
import insta2 from '../assets/images/insta2.png';
import insta3 from '../assets/images/insta3.png';
import insta4 from '../assets/images/insta4.png';
import insta5 from '../assets/images/insta5.png';
import insta6 from '../assets/images/insta6.png';

const Instagram = () => {
  const images = [insta1, insta2, insta3, insta4, insta5, insta6];

  return (
    <section className='Gallery'>
      <div className="gallery-container">
        <h3>FOLGE UNS IN INSTAGRAM</h3>
        <div className="profile-info">
          <img src={profile} alt="Profile" className="profile-picture" />
          <div className="profile-details">
            <div className="button-container">
              <h2 className="username">Muranobeachclub</h2>
              <a href="https://www.instagram.com/muranobeachclub/" target="_blank" rel="noopener noreferrer" className="follow-button">
                <i className="fab fa-instagram"></i>
                <span>Follow</span>
              </a>
            </div>
            <p className="bio link">party and entertainment service</p>
            <p className="bio">Murner See Sonnenriederstr.7 in 92442 Wackersdorf</p>
            <p className="bio">we cover you with sunshine</p>
            <p className="bio">& shower you with good times!</p>
          </div>
        </div>
        <div className="image-grid">
          {images.map((image, index) => (
            <img key={index} src={image} alt={`insta ${index + 1}`} className="image-item" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Instagram;
