import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useState } from "react";
import Socials from "./Socials";
import logo from "../assets/images/Logo.final1.jpg";
import logoSide from "../assets/images/logo.png";

const InnerNav = () => {
  const [dropdown, setDropdown] = useState(false);
  const [counter, setCounter] = useState(false);

  return (
    <>
      <div
        className="logo"
        onClick={() => {
          scroll.scrollToTop();
        }}
      >
        {/* Use Link component from react-router-dom */}
        <Link to="/" smooth={true} className="logo nav-link">
          <img className="logo-img" src={logo} alt="EsyMedia Logo" />
        </Link>
      </div>
      <div className="rightNav">
        <Socials />
        <div
          className={`buttonWrapper ${dropdown ? "open" : "closed"}`}
          onClick={() => {
            setDropdown(!dropdown);
            setCounter(true);
          }}
        >
          <div className="hamburger"></div>
        </div>
      </div>
      <div
        className={
          counter === false
            ? "mainDropdown"
            : dropdown
            ? "mainDropdown slideRight"
            : "mainDropdown slideLeft"
        }
      >
        <div
          className="sidebar"
          style={dropdown ? { boxShadow: "0 10px 100px #000" } : null}
        >
          <div
          className="logo"
          onClick={() => {
            setDropdown(false);
            setCounter(true);
            scroll.scrollToTop();
          }}
        >
          <Link to="/" smooth={true} className="logo nav-link">
            {/* Replace the h1 tag with the img tag for the logo */}
            <img className="logoSide-img" src={logoSide} alt="EsyMedia Logo" />
          </Link>
        </div>
          <div className="innerSidebar">
            <Link
              to="/"
              onClick={() => {
                setDropdown(false);
                setCounter(true);
              }}
            >
              <button >Home</button>
            </Link>

            <Link
              to="/events"
              onClick={() => {
                setDropdown(false);
                setCounter(true);
              }}
            >
              <button>Events</button>
            </Link>

            <Link
              to="/drinks"
              onClick={() => {
                setDropdown(false);
                setCounter(true);
              }}
            >
              <button>Drinks</button>
            </Link>

            <Link
              to="/wassersport"
              onClick={() => {
                setDropdown(false);
                setCounter(true);
              }}
            >
              <button>Wassersport</button>
            </Link>
          </div>
          <Socials />
          <div
            className={`buttonWrapper open`}
            onClick={() => {
              setDropdown(!dropdown);
              setCounter(true);
            }}
          >
            <div className="hamburger"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerNav;
