import React from 'react';

const MapSection = () => {
  return (
    <section className="map-section">
      <div className="content-container">
        <div className="address-container">
          <h3>KOMM VORBEI</h3>
          <div className="address-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-geo-alt"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a6 6 0 0 0-6 6c0 3.816 5.26 9.687 5.477 9.938a.5.5 0 0 0 .946 0C10.74 15.687 16 9.816 16 6a6 6 0 0 0-6-6zm0 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
            </svg>
            <p className="address">Murner See Sonnenriederstr.7 in 92442 Wackersdorf</p>
          </div>
        </div>
        <div className="map-container">
          <iframe
            title="Map"
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d0.001045910748938181!2d12.207789988553899!3d49.34760544189046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ff5120aa04577%3A0xa326dbb43f212955!2sMurano%20Beachclub%20%2F%20Sunset%20Beach%20Bar%20Murner%20See%20am%20Theatron!5e0!3m2!1sen!2sca!4v1633368057582!5m2!1sen!2sca&z=10"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <style jsx>{`
        .map-section {
          padding: 20px;
        }

        .content-container {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          flex-wrap: wrap;
          padding-left: 10rem;
          padding-right: 10rem;
        }

        .address-container {
          flex: 1;
          text-align: center;
          margin-bottom: 20px;
        }

        .section-title {
          padding-bottom: 20px;
        }

        .address-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .address-icon svg{
          margin-right: 8px;
        }

        .map-container {
          flex: 1;
          max-width: 800px; /* Adjust the maximum width as needed */
          overflow: hidden;
        }

        .map {
          width: 100%;
          height: 400px; /* Adjust the height as needed */
        }

        @media screen and (max-width: 600px) {
          .content-container {
            flex-direction: column;
            padding: 0;
          }
        }
      `}</style>
    </section>
  );
};

export default MapSection;
