import React, { Component } from "react";
import classnames from "classnames";
import InnerNav from "./InnerNav";

export default class MobileNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      background: false
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = true;
    console.log(prevScrollpos, currentScrollPos);
    const background = currentScrollPos > 150;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      background
    });
  };

  render() {
    return (
      <nav
        className={classnames(
          "navbar mobile",
          {
            "navbar--hidden mobile": !this.state.visible
          },
          {
            "navbar--background mobile": this.state.background
          }
        )}
      >
        <InnerNav />
      </nav>
    );
  }
}
