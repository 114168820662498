import "./index.css";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Drinks from './pages/Drink';
import Wassersport from "./pages/Wassersport";
import EventPage from "./pages/Events";
import CookieBanner from "./components/CookieBanner";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";

AOS.init({
  once: true
});
export default function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/drinks" element={<Drinks/>} />
            <Route path="/wassersport" element={<Wassersport/>} />
            <Route path="/events" element={<EventPage/>} />
            <Route path="/impressum" element={<Impressum/>} />
            <Route path="/datenschutz" element={<Datenschutz/>} />
          </Routes>
        </BrowserRouter>
        <CookieBanner/>
      </div>
  );
}
